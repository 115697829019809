import {
    DeputySortType,
} from 'pages/DeputyProfile/components/FederalCoordinatorProfile/components/DeputyPoints/hooks/types';
import { getTimeZone } from 'utils/dateTime';
import { INewsFilterValues } from 'pages/News/components/NewsFilter/NewsFilter';
import { IGroupsListRequest, IGroupsMessageRequest, MessagesSortType } from 'api/types/v1.0/groupsList';
import { Role } from 'types/account';
import { ProjectType } from '../types/v1.0/activityInfo';
import { ICitizenGroupRequest } from '../types/v1.0/citizenGroup';
import { IFavoritePointRequest } from '../types/v1.0/favoritePoint';
import { INewsListRequest } from '../types/v1.0/newsList';
import { TypeOfNews } from '../../components/Layout/components/Sidebar/News/hooks/types';
import { IBarsSort, SortDirection, SortType } from './barsSortObject';

export interface IBarsFilter {
    Group: number;
    Filters: IBarsFilter[] | IBarsFilter | IBarsFilterData[];
}

export interface IBarsFilterData {
    Operand: number;
    Value: string | number | null;
    DataIndex: string;
}

export interface IHasFilters {
    dataFilter?: IBarsFilter | null;
}

export interface IHasTimeZone {
    clientTimezoneParams: {
        clientTimeZoneOffset: number;
    };
}

const setProjectFilters = (projectIds?: number[] | null) => {
    const secondGroupFilters: IBarsFilterData[] = [];
    if (projectIds) {
        projectIds.forEach((projectId) => {
            secondGroupFilters.push({
                Operand: 0,
                Value: projectId.toString(),
                DataIndex: 'project_id',
            });
        });
    }

    return secondGroupFilters;
};

export const getBarsCoordinatorPointsFilter = (operand: number, value: number, dataIndex: string) => {
    return {
        Group: 3,
        Filters: [getBarsCoordinatorsFilter(operand, value, dataIndex)],
    };
};

export const getBarsCoordinatorsFilter = (operand: number, value: number, dataIndex: string) => {
    return {
        Operand: operand,
        Value: value,
        DataIndex: dataIndex,
    };
};

export const getBarsCoordinatorDeputyFilter = (operand: number, deputyId: number, coordinatorId?: number) => {
    const secondGroupFilters = [];

    secondGroupFilters.push({
        Operand: operand,
        Value: deputyId,
        DataIndex: 'municipal_response_id',
    });

    secondGroupFilters.push({
        Operand: operand,
        Value: coordinatorId,
        DataIndex: 'Id',
    });

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getBarsCoordinatorsSort = (sortType: SortType | DeputySortType, sortDirection: SortDirection) => {
    return [
        {
            property: sortType,
            direction: sortDirection,
        },
    ];
};

export const getDataFilter = (deputyId: number) => {
    const filter = {
        Operand: 0,
        Value: deputyId,
        DataIndex: 'deputy_id',
    } as IBarsFilterData;
    return JSON.stringify(filter);
};

export const getSort = (sortType: SortType, sortDirection: SortDirection) => {
    const sort = {
        property: sortType,
        direction: sortDirection,
    } as IBarsSort;
    const json = JSON.stringify(sort);
    return '['.concat(json).concat(']');
};

export interface IHasPagination {
    page?: number;
    start?: number;
    limit?: number;
}

export const getBarsProjectDeputyActivityFilter = (userId?: string | null, projectIds?: number[] | null) => {
    if (!projectIds && !userId) {
        return null;
    }

    const secondGroupFilters = setProjectFilters(projectIds);

    if (userId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: userId,
            DataIndex: 'Id',
        });
    }

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getGroupMembersListFilter = (object_id: number, filter_name?: string) => {
    const secondGroupFilters: IBarsFilterData[] = [];

    if (object_id) {
        secondGroupFilters.push({
            Operand: 0,
            Value: object_id,
            DataIndex: 'object_id',
        });
    }

    if (filter_name) {
        secondGroupFilters.push({
            Operand: 0,
            Value: filter_name,
            DataIndex: 'name',
        });
    }

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getNewsPageListFilter = (input: INewsListRequest) => {
    const {
        projectId,
        objectId,
        regionId,
        deputyId,
        startDate,
        endDate,
    } = input;

    const secondGroupFilters: IBarsFilterData[] = [];

    if (deputyId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: deputyId.toString(),
            DataIndex: 'deputy_id',
        });
    }

    if (projectId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: projectId.toString(),
            DataIndex: 'project_id',
        });
    }

    if (objectId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: objectId.toString(),
            DataIndex: 'object_id',
        });
    }

    if (regionId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: regionId.toString(),
            DataIndex: 'region_id',
        });
    }

    if (startDate) {
        secondGroupFilters.push({
            Operand: 4,
            Value: startDate.toString(),
            DataIndex: 'date_time',
        });
    }

    if (endDate) {
        secondGroupFilters.push({
            Operand: 5,
            Value: endDate.toString(),
            DataIndex: 'date_time',
        });
    }

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getNewsListFilter = ({
    projectId,
    pointId,
    regionId,
    deputyId,
    startDate,
    endDate,
    type,
    pageCurrentObject,
}: INewsFilterValues) => {
    const secondGroupFilters = [];

    if (projectId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: projectId.toString(),
            DataIndex: 'project_id',
        });
    }

    if (pointId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: pointId.toString(),
            DataIndex: 'object_id',
        });
    }

    if (deputyId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: deputyId.toString(),
            DataIndex: 'deputy_id',
        });
    }

    if (regionId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: regionId.toString(),
            DataIndex: 'region_id',
        });
    }

    if (startDate) {
        secondGroupFilters.push({
            Operand: 4,
            Value: startDate.toString(),
            DataIndex: 'date_time',
        });
    }

    if (endDate) {
        secondGroupFilters.push({
            Operand: 5,
            Value: endDate.toString(),
            DataIndex: 'date_time',
        });
    }

    if (type !== undefined) {
        switch ( type ){
            case TypeOfNews.Regional:
                pageCurrentObject ? secondGroupFilters.push({
                    Group: 3,
                    Filters: [
                        {
                            Operand: 0,
                            Value: type,
                            DataIndex: 'type',
                        },
                        {
                            Operand: 0,
                            Value: null,
                            DataIndex: 'type',
                        },
                    ],
                }) : secondGroupFilters.push({
                    Group: 3,
                    Filters: [
                        {
                            Operand: 0,
                            Value: type,
                            DataIndex: 'type',
                        },
                    ],
                });
                break;
            case TypeOfNews.Federal:
                secondGroupFilters.push({
                    Operand: 0,
                    Value: type,
                    DataIndex: 'type',
                });
                break;
        }
    }

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getBarsProjectFilter = (projectIds: number[] | null, userId?: string | null) => {
    if (!projectIds && !userId) {
        return null;
    }

    const secondGroupFilters = setProjectFilters(projectIds);

    if (userId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: userId,
            DataIndex: 'Id',
        });
    }

    return {
        Group: 3,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getBarsPointInfoFilter = (pointIds: number[] | null, userId?: string | null) => {
    if (!pointIds && !userId) {
        return null;
    }

    const secondGroupFilters: IBarsFilterData[] = [];

    if (pointIds) {
        pointIds.forEach((objectId) => {
            secondGroupFilters.push({
                Operand: 0,
                Value: objectId.toString(),
                DataIndex: 'Object_Id',
            });
        });
    }

    if (userId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: userId,
            DataIndex: 'Id',
        });
    }

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getDeputyActivitiesSort = (): IBarsSort[] => {
    return [
        {
            property: 'date_time',
            direction: 'DESC',
        },
    ];
};

export const getDeputyActivitiesFilter = (
    projectId?: number | null,
    objectId?: number | null,
    selectedDate?: string | null,
    userId?: string | null,
    eventStateId?: number | null,
    objectEventId?: number | null,
    filterDateForMonth?: boolean | null
): IBarsFilter | null => {
    if (!projectId && !objectId && !selectedDate && !userId) {
        return null;
    }

    const secondGroupFilters: IBarsFilterData[] = [];

    if (projectId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: String(projectId),
            DataIndex: 'project_id',
        });
    }

    if (objectId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: String(objectId),
            DataIndex: 'object_id',
        });
    }

    if (selectedDate) {
        const { start, end } = getDatesForFilter(selectedDate, filterDateForMonth);
        secondGroupFilters.push(
            {
                Operand: 4,
                Value: start.toISOString(),
                DataIndex: 'FieldPath://39bd0d2f-b2c7-43b2-8444-1278cb8448d7$eb3ced74-b639-43a0-adb5-dc6288190546',
            },
            {
                Operand: 5,
                Value: end.toISOString(),
                DataIndex: 'FieldPath://39bd0d2f-b2c7-43b2-8444-1278cb8448d7$eb3ced74-b639-43a0-adb5-dc6288190546',
            }
        );
    }

    if (userId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: userId,
            DataIndex: 'Id',
        });
    }

    if (objectEventId)
    {
        secondGroupFilters.push({
            Operand: 0,
            Value: objectEventId,
            DataIndex: 'object_event_id',
        });
    }

    if (eventStateId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: eventStateId,
            DataIndex: 'state_id',
        });
    }

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getDeputyActivitiesFilterForReports = (
    projectId?: number | null,
    objectId?: number | null,
    selectedDate?: string | null,
    userId?: string | null,
    objectEventId?: number | null,
    eventReport?: boolean | null,
    stateId?: number | null
): IBarsFilter | null => {
    if (!projectId && !objectId && !selectedDate && !userId) {
        return null;
    }

    const secondGroupFilters: IBarsFilterData[] = [];

    if (projectId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: String(projectId),
            DataIndex: 'project_id',
        });
    }

    if (objectId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: String(objectId),
            DataIndex: 'object_id',
        });
    }

    if (selectedDate) {
        const { date } = getDateEndForFilter(selectedDate);
        secondGroupFilters.push(
            {
                Operand: 5,
                Value: date.toISOString(),
                DataIndex: 'FieldPath://39bd0d2f-b2c7-43b2-8444-1278cb8448d7$eb3ced74-b639-43a0-adb5-dc6288190546',
            }
        );
    }

    if (userId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: userId,
            DataIndex: 'Id',
        });
    }

    if (objectEventId)
    {
        secondGroupFilters.push({
            Operand: 0,
            Value: objectEventId,
            DataIndex: 'object_event_id',
        });
    }

    if (eventReport)
    {
        secondGroupFilters.push({
            Operand: 0,
            Value: eventReport ? 'true' : 'false',
            DataIndex: 'event_report',
        });
    }

    eventReport ? (
        secondGroupFilters.push({
            Operand: 0,
            Value: 'true',
            DataIndex: 'event_report',
        })
    ) : (
        secondGroupFilters.push({
            Operand: 0,
            Value: 'false',
            DataIndex: 'event_report',
        })
    );

    if (stateId)
    {
        secondGroupFilters.push({
            Operand: 0,
            Value: stateId,
            DataIndex: 'state_id',
        });
    }

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getUsersToInviteFilter = (
    projectId: number | null,
    objectId: number | null,
    id: number): IBarsFilter => {
    const secondGroupFilters: IBarsFilterData[] = [];
    if (projectId) {
        secondGroupFilters.push({ Operand: 0, Value: projectId, DataIndex: 'project_id' });
    }

    if (objectId) {
        secondGroupFilters.push({ Operand: 0, Value: objectId, DataIndex: 'object_id' });
    }

    secondGroupFilters.push({ Operand: 0, Value: id, DataIndex: 'Id' });

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getEventsEntityListFilters = (project_type: ProjectType) => {
    const secondGroupFilters: IBarsFilterData[] = [];
    secondGroupFilters.push({
        Operand: 0,
        Value: project_type,
        DataIndex: 'type_project',
    });

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getFavoritePointFilter = (request: IFavoritePointRequest) => {
    const secondGroupFilters: IBarsFilterData[] = [];
    if (request.CitizenId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: request.CitizenId,
            DataIndex: 'CitizenId.Id',
        });
    }

    if (request.ObjectId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: request.ObjectId,
            DataIndex: 'ObjectId.Id',
        });
    }

    if (request.ObjectName) {
        secondGroupFilters.push({
            Operand: 6,
            Value: request.ObjectName,
            DataIndex: 'ObjectId.object_short_name',
        });
    }

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getFavoritePoints = (request: IFavoritePointRequest) => {
    const secondGroupFilters: IBarsFilterData[] = [];
    if (request.CitizenId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: request.CitizenId,
            DataIndex: 'CitizenId.Id',
        });
    }

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getGroupsListFilter = (request: IGroupsListRequest) => {
    const secondGroupFilters: IBarsFilterData[] = [];

    if (request.project_id) {
        secondGroupFilters.push({
            Operand: 0,
            Value: request.project_id,
            DataIndex: 'project_id',
        });
    }

    if (request.user_role !== Role.Citizen){
        secondGroupFilters.push({
            Operand: 1,
            Value: null,
            DataIndex: 'Id',
        });
    }

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getGroupsMessagesFilter = (request: IGroupsMessageRequest) => {
    return {
        'Group': 2,
        Filters: [{
            Group: 3,
            Filters: [{
                Operand: 0,
                DataIndex: 'object_id',
                Value: request.object_Id,
            }],
        }],
    };
};

export const getGroupsMessagesSort = (sortType: MessagesSortType, sortDirection: SortDirection) => {
    return [
        {
            property: sortType,
            direction: sortDirection,
        },
    ];
};

export const getCitizenGroupFilter = (request: ICitizenGroupRequest) => {
    const secondGroupFilters: IBarsFilterData[] = [];
    if (request.CitizenId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: request.CitizenId,
            DataIndex: 'Id',
        });
    }

    if (request.ObjectId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: request.ObjectId,
            DataIndex: 'object_id',
        });
    }

    if (request.ProjectId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: request.ProjectId,
            DataIndex: 'project_id',
        });
    }

    if (request.ObjectName) {
        secondGroupFilters.push({
            Operand: 6,
            Value: request.ObjectName,
            DataIndex: 'object_name',
        });
    }

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getBarsRegionFilter = (project_Id?: number | null) => {
    if (!project_Id) {
        return null;
    }

    const secondGroupFilters: IBarsFilterData[] = [];
    secondGroupFilters.push({ Operand: 0, Value: project_Id, DataIndex: 'project_id' });

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getBarsProjectAndRegionFilter = (
    regionIds?: number | null | undefined,
    projectId?: number[] | undefined
) => {
    if (!regionIds && !projectId) {
        return null;
    }

    const secondGroupFilters: IBarsFilterData[] = [];

    if (regionIds) {
        secondGroupFilters.push({
            Operand: 0,
            Value: String(regionIds),
            DataIndex: 'region_id',
        });
    }

    if (projectId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: projectId[0],
            DataIndex: 'project_id',
        });
    }

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getCitizenEventsFilter = (
    userId: string,
    eventId?: number | null,
    projectId?: number | null,
    objectId?: number | null,
    selectedDate?: string | null,
    filterDateForMonth?: boolean | null): IBarsFilter | null => {
    const secondGroupFilters: IBarsFilterData[] = [];

    if (projectId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: String(projectId),
            DataIndex: 'project_id',
        });
    }

    if (objectId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: String(objectId),
            DataIndex: 'object_id',
        });
    }

    if (eventId) {
        secondGroupFilters.push({
            Operand: 0,
            Value: eventId,
            DataIndex: 'Id',
        });
    }

    if (selectedDate) {
        const { start, end } = getDatesForFilter(selectedDate, filterDateForMonth);

        secondGroupFilters.push(
            {
                Operand: 4,
                Value: start.toISOString(),
                DataIndex: 'event_date_time',
            },
            {
                Operand: 5,
                Value: end.toISOString(),
                DataIndex: 'event_date_time',
            });
    }

    secondGroupFilters.push({
        Operand: 0,
        Value: userId,
        DataIndex: 'citizen_id',
    });

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getNotificationsFilter = (
    id: number
): IBarsFilter | null => {
    const secondGroupFilters: IBarsFilterData[] = [];

    secondGroupFilters.push({
        Operand: 0,
        Value: id,
        DataIndex: 'citizen_id',
    });

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getIndicatorFilter = (
    id: number
): IBarsFilter | null => {
    const secondGroupFilters: IBarsFilterData[] = [];

    secondGroupFilters.push({
        Operand: 0,
        Value: id,
        DataIndex: 'citizen_id',
    });

    secondGroupFilters.push({
        Operand: 0,
        Value: 0,
        DataIndex: 'viewed',
    });

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

const getDatesForFilter = (selectedDate: string, filterDateForMonth: boolean | null | undefined) => {
    const date = new Date(selectedDate);
    if (filterDateForMonth) { date.setMilliseconds(getTimeZone() * 60 * 60 * 1000); }

    const start = getStartEndDate(date,filterDateForMonth,true);
    const end = getStartEndDate(date,filterDateForMonth,false);
    start?.setHours(0, 0, 0, 0);
    end?.setHours(23, 59, 59, 999);

    return { start, end };
};

const getStartEndDate = (date:Date, filterDateForMonth: boolean | null | undefined, isStart: boolean) => {
    return filterDateForMonth? new Date(date.getFullYear(), isStart ? date.getMonth()
        : date.getMonth() + 1 , isStart ? 1 : 0)
        : new Date(date.getFullYear(),date.getMonth(),getTimeZone() > 0 ? date.getDate() + 1 : date.getDate());
};

const getDateEndForFilter = (selectedDate: string) => {
    const date = new Date(selectedDate);

    return { date };
};

export const getDeputyListFilter = (
    searchInput: string | null
): IBarsFilter | null => {
    const secondGroupFilters: IBarsFilterData[] = [];

    if (searchInput && searchInput.length > 0) {
        secondGroupFilters.push({
            Operand: 6,
            Value: searchInput,
            DataIndex: 'surname_n_p',
        });
    }

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};

export const getDeputyQuestionsFilter = (
    deputy_id: string | null,
    object_id: string | null,
    isCoordinator?: boolean
) => {
    const secondGroupFilters = [];

    if (deputy_id) {
        secondGroupFilters.push(
            { Value: deputy_id, DataIndex: isCoordinator ? 'coordinator_id' : 'deputy_id', Operand: 0 }
        );
    }

    if (object_id) {
        secondGroupFilters.push(
            { Value: object_id, DataIndex: 'object_id', Operand: 0 }
        );
    }

    return {
        Group: 2,
        Filters: secondGroupFilters,
    } as IBarsFilter;
};
