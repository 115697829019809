import React from 'react';
import { Button, DatePicker, Divider } from 'antd';
import moment, { Moment } from 'moment';
import { DEFAULT_DATE_FORMAT } from 'constants/dateTime';
import { IFilterStyleType, ProjectFilter } from 'ui-kit/filters/ProjectFilter';
import { PointFilter } from 'ui-kit/filters/PointFilter';
import { RegionFilter } from 'ui-kit/filters/RegionFilter';
import { DeputyFilter } from 'ui-kit/filters/DeputyFilter';
import { TypeOfNews } from 'components/Layout/components/Sidebar/News/hooks/types';
import styles from './NewsFilter.module.scss';
import 'ui-kit/Form/FormFields/DatePickerField/DatePickerField.scss';

export interface INewsFilterValues {
    projectId?: number;
    pointId?: number;
    regionId?: number;
    deputyId?: number;
    startDate?: Date;
    endDate?: Date;
    type?: TypeOfNews;
    pageCurrentObject?: boolean;
}

interface IFilterProps {
    regionId: number | null;
    projectId: number | null;
    deputyId: number | null,
    pointId: number | null;
    startDate: string | null;
    endDate: string | null;
    setSelectedRegionId: (id: number | null) => void;
    setSelectedProjectId: (id: number | null) => void;
    setSelectedObjectId: (id: number | null) => void;
    setSelectedDeputyId: (id: number | null) => void;
    setSelectedStartDate: (value: string | null) => void;
    setSelectedEndDate: (value: string | null) => void;
    clearFilters: () => void;
    submitFilterChanges: () => void;
    isLoading: boolean;
}

const NewsFilter: React.FC<IFilterProps> = (props) => {
    const hasAnyFilterValue = props.regionId || props.projectId || props.pointId || props.deputyId ||
        props.startDate || props.endDate;

    const format = DEFAULT_DATE_FORMAT;

    return (
        <div className={styles.newsFilters}>
            <div className={styles.filtersTitle}>
                Выбор
            </div>
            <Divider />
            <div className={styles.filtersSubtitle}>
                Основное:
            </div>
            <div className={styles.regionFilter}>
                <RegionFilter
                    type={IFilterStyleType.outlined}
                    placeholder='Регион'
                    onChange={props.setSelectedRegionId}
                    managedValue={props.regionId}
                    alwaysShowSuffix
                />
            </div>
            <div className={styles.projectFilter}>
                <ProjectFilter
                    type={IFilterStyleType.outlined}
                    placeholder='Проект'
                    onChange={props.setSelectedProjectId}
                    managedValue={props.projectId}
                    alwaysShowSuffix
                    needClearProjectId={true}
                />
            </div>
            <div className={styles.pointFilter}>
                <PointFilter
                    type={IFilterStyleType.outlined}
                    projectId={props.projectId ?? undefined}
                    placeholder='Объект'
                    onChange={props.setSelectedObjectId}
                    managedValue={props.pointId}
                    alwaysShowSuffix
                />
            </div>
            <div className={styles.deputyFilter}>
                <DeputyFilter
                    type={IFilterStyleType.outlined}
                    placeholder='Ответственный'
                    onChange={props.setSelectedDeputyId}
                    managedValue={props.deputyId}
                    alwaysShowSuffix
                    needClearDeputyId={true}
                />
            </div>
            <div className={styles.filtersSubtitle}>
                Задайте период:
            </div>
            <div className={styles.datePickersWrapper}>
                <DatePicker
                    value={props.startDate ? moment.utc(props.startDate, format) : undefined}
                    onChange={(_: Moment | null, dateString: string) => props.setSelectedStartDate(dateString)}
                    format={format}
                    className={'date-picker-field'}
                    placeholder={'Начало'}
                    suffixIcon={null}
                />
                <DatePicker
                    value={props.endDate ? moment.utc(props.endDate, format) : undefined}
                    onChange={(_: Moment | null, dateString: string) => props.setSelectedEndDate(dateString)}
                    format={format}
                    className={'date-picker-field'}
                    placeholder={'Конец'}
                    suffixIcon={null}
                />
            </div>
            <Divider />
            <div className={styles.filterButtons}>
                <Button
                    className={styles.buttonCancel}
                    onClick={props.clearFilters}
                >
                    Сбросить
                </Button>
                <Button
                    className={styles.buttonSubmit}
                    disabled={!hasAnyFilterValue}
                    loading={props.isLoading}
                    onClick={props.submitFilterChanges}
                >
                    Применить
                </Button>
            </div>
        </div>
    );
};

export default NewsFilter;
