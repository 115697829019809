import React, { useEffect, useState } from 'react';
import { Divider, List, Modal, Spin } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { IPreviewListItem } from 'api/types/v1.0/groupsList';
import { RootState } from 'App/root/rootReducer';
import { ProjectFilter } from 'ui-kit/filters/ProjectFilter';
import { hasRole } from 'utils/user';
import * as actionCreators from 'ducks/chat/actionCreators';
import { Role } from 'types/account';
import ChatConversationCard from './components/ChatConversationCard/ChatConversationCard';
import ChatPreviewCard from './components/ChatPreviewCard/ChatPreviewCard';
import useChatModal from './hooks/useChatModal';
import GroupListMembers from './components/GroupMembersListCard/GroupListMembers';
import styles from './ChatModal.module.scss';

interface IChatModalProps {
    groupId?: number;
}

const ChatModal: React.FC<IChatModalProps> = ({ groupId }) => {
    const { isChatOpen, selectedChatId, isResponse, selectedProjectId, isGroupMembersOpen } =
        useSelector((state: RootState) => state.chat);
    const dispatch = useDispatch();
    const [isPreviewHidden, setIsPreviewHidden] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<{groupId: number, is_response?: boolean}>({
        groupId: selectedChatId as number,
        is_response: true,
    });
    const [isGroupMembersListHidden, setIsGroupMembersListHidden] = useState(!isGroupMembersOpen);
    const [projectId, setProjectId] = useState<number | null>(selectedProjectId);
    const { user } = useSelector((state: RootState) => state.account);
    const {
        groupsList,
        groupsTotalCount,
        groupMessages,
        messagesTotalCount,
        isChatMessagesLoading,
        isPreviewLoading,
        isSendMessageLoading,
        groupMembersCount,
        refresh,
        getGroupMessagesList,
        setEmptyGroupMessageList,
        getInitialGroupMessageList,
        sendMessageSeen,
        replyMessage,
        sendMessage,
        removeMessage,
        getGroupMembersCount,
        changeMessagesSort,
        sortType,
        sortDirection,
    } = useChatModal(user?.sub);
    const isEditDisabled = hasRole([Role.Municipal], user?.role as Role) && !selectedGroup.is_response;
    const styleChat = isPreviewHidden ? styles.chatConverstationFull : styles.chatConverstation;
    const styleMissingChat = isPreviewHidden ? styles.missingChatTextWrapperHiddenPreview
        : styles.missingChatTextWrapper;

    const loadMoreData = () => {
        refresh(projectId, groupsList?.length);
    };

    const handlePageRefresh = () => {
        dispatch(actionCreators.setChatModalState(false));
        dispatch(actionCreators.setIsResponse(false));
        dispatch(actionCreators.setSelectedChatId(null));
        dispatch(actionCreators.setSelectedMessageId(null));
        dispatch(actionCreators.setSelectedProjectId(null));
    };

    const handleChangeProject = (newProjectId: number | null) => {
        setProjectId(newProjectId);
        dispatch(actionCreators.setSelectedProjectId(newProjectId));
    };

    useEffect(() => {
        getInitialGroupMessageList(selectedGroup.groupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortType, sortDirection]);

    useEffect(() => {
        refresh(projectId);

        window.addEventListener('beforeunload', handlePageRefresh);

        return () => {
            window.removeEventListener('beforeunload', handlePageRefresh);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    useEffect(() => {
        setSelectedGroup({ groupId: selectedChatId as number, is_response: isResponse });
    }, [selectedChatId, isResponse]);

    useEffect(() => {
        setIsGroupMembersListHidden(!isGroupMembersOpen);
    }, [isGroupMembersOpen]);

    return (
        <Modal
            className={styles.chatModal}
            footer={null}
            open={isChatOpen}
            onCancel={() => {
                dispatch(actionCreators.setChatModalState(false));
                dispatch(actionCreators.setSelectedProjectId(null));
                dispatch(actionCreators.setSelectedMessageId(null));
            }}
            maskClosable={false}
            mask={false}
            closable={isGroupMembersListHidden}
            wrapClassName={isPreviewHidden ? 'modal-wrapper modal-preview-hidden' : 'modal-wrapper'}
        >
            <Spin spinning={isPreviewLoading || isChatMessagesLoading || isSendMessageLoading}>
                <div className={styles.chatModal}>
                    <div className={styles.chatModalPreview} hidden={isPreviewHidden}>
                        <div className={styles.chatModalPreviewTitleWrapper}>
                            <span className={styles.chatModalPreviewTitle}>Чаты</span>
                            <LeftOutlined onClick={() => setIsPreviewHidden(true)} />
                        </div>
                        <div className={styles.projectFilter}>
                            <ProjectFilter
                                onChange={(value) => handleChangeProject(value)}
                                managedValue={selectedProjectId}
                                userId={user?.sub}
                                onClear={() => {
                                    dispatch(actionCreators.setSelectedProjectId(null));
                                }}
                            />
                        </div>
                        <InfiniteScroll
                            className={styles.infiniteScrollComponent}
                            dataLength={groupsList?.length ? groupsList?.length : 0}
                            next={loadMoreData}
                            height={448}
                            hasMore={groupsList ? groupsList?.length < groupsTotalCount : false}
                            scrollableTarget="scrollableDiv"
                            loader={''}
                        >
                            <Divider className={styles.previewDivider} />
                            <List
                                dataSource={groupsList ?? []}
                                renderItem={(previewDetails: IPreviewListItem) => (
                                    <div
                                        className={styles.chatPreviewList}
                                        onClick={() => {
                                            setEmptyGroupMessageList();
                                            dispatch(actionCreators.setSelectedChatId(previewDetails.object_id));
                                            dispatch(actionCreators.setIsResponse(previewDetails.is_response));
                                            dispatch(actionCreators.setSelectedMessageId(null));
                                        }}
                                    >
                                        <ChatPreviewCard
                                            previewDetails={previewDetails}
                                            selectedGroup={selectedGroup.groupId}
                                        />
                                    </div>
                                )}
                            />
                        </InfiniteScroll>
                    </div>
                    <div className={styles.showPreviewIcon} hidden={!isPreviewHidden}>
                        <RightOutlined onClick={() => setIsPreviewHidden(false)} />
                    </div>
                    <Divider className={styles.divider} type='vertical' />
                    {isGroupMembersListHidden &&
                        (selectedGroup.groupId
                            ? <div className={styleChat}>
                                <ChatConversationCard
                                    isChatMessagesLoading={isChatMessagesLoading}
                                    isSendMessageLoading={isSendMessageLoading}
                                    sendMessage={sendMessage}
                                    group_id={selectedGroup.groupId}
                                    groupMessages={groupMessages}
                                    groupMembersCount={groupMembersCount}
                                    getGroupMembersCount={getGroupMembersCount}
                                    replyMessage={replyMessage}
                                    messagesTotalCount={messagesTotalCount}
                                    isEditDisabled={isEditDisabled}
                                    getGroupMessagesList={getGroupMessagesList}
                                    getInitialGroupMessageList={getInitialGroupMessageList}
                                    sendMessageSeen={sendMessageSeen}
                                    setIsGroupMembersListHidden={setIsGroupMembersListHidden}
                                    removeMessage={removeMessage}
                                    changeMessagesSort={changeMessagesSort}
                                />
                            </div>
                            : !groupId &&
                                <div className={styleMissingChat}>
                                    <span className={styles.missingChatText}>
                                        Выберите чат в который хотите написать или
                                        перейдите на страницу объектов и войдите в новый
                                    </span>
                                </div>
                        )
                    }
                    {!isGroupMembersListHidden && <div className={isPreviewHidden
                        ? styles.groupMembersListCardWrapperFull
                        : styles.groupMembersListCardWrapper
                    }>
                        <GroupListMembers
                            setIsGroupMembersListHidden={setIsGroupMembersListHidden}
                            isEditDisabled={isEditDisabled}
                            selectedGroup_Id={selectedGroup.groupId}
                        />
                    </div>}
                </div>
            </Spin>
        </Modal>
    );
};

export default ChatModal;
